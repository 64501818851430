import { AppState } from '../app.reducers';
import { createSelector } from '@ngrx/store';
import { SiteState } from '../reducers/site.reducer';

export const selectSiteStateFeature = (state: AppState) => state.siteState;

export const getSiteLanguage = createSelector(
    selectSiteStateFeature,
    (state: SiteState) => state.siteLanguage
);

export const getSiteCategories = createSelector(
    selectSiteStateFeature,
    (state: SiteState) => state.siteCategories
);
